.CookieConsent {
    max-width: 80%;
    border-radius: 2rem;
    padding: 1rem 2rem;
    background-color: white;
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    transform: translate3d(-50%, -50%, 9999990px);
    width: 50%;
    /* box-shadow: 0 3px 10px rgba(0,0,0,0.1); */
    box-shadow: 0 0.15rem 1rem red

  }
  
  .overlayclass { 
    position: fixed;
    background-color: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }