/* Main container */

.App {
  display: grid;
  grid-template-columns: 1fr;
  text-align: center;
  margin: 0 0.15rem;
  font-family: "Work Sans", sans-serif;
  font-size: 1rem;

  background-color: #000028;
  min-height: 20vh;
  background-image: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.7) 20%,
      transparent 30%
    ),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%);
  background-size: 0.5rem 0.5rem, 0.25rem 0.25rem, 0.75rem 0.75rem,
    0.25rem 0.35rem, 1rem 1rem, 0.75rem 0.75rem, 1rem 1rem, 0.75rem 0.75rem,
    1.25rem 1.25rem, 0.5rem 0.5rem, 0.75rem 0.75rem, 0.5rem 0.5rem,
    0.6rem 0.6rem, 1.25rem 1.25rem, 0.75rem 0.75rem, 0.5rem 0.5rem,
    0.75rem 0.75rem, 1.25rem 1.25rem;
  background-position: 20% 10%, 5% 20%, 10% 75%, 22% 35%, 30% 30%, 40% 50%,
    45% 20%, 65% 20%, 85% 30%, 98% 90%, 20% 80%, 50% 80%, 75% 80%, 89% 75%,
    90% 95%, 60% 70%, 65% 40%, 90% 20%;
  background-repeat: no-repeat;
}

.overlayclass {
  color: black;
  font-size: 0.75rem;
}
.msg2 {
  font-size: 0.5rem;
  color: #000028;
}

/* Container header */
.Container-Header {
  color: white;

  /* aqui comienza el cielo estrellado */

  background-color: #000028;
  min-height: 30vh;
  background-image: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.7) 20%,
      transparent 30%
    ),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%);
  background-size: 0.5rem 0.5rem, 0.25rem 0.25rem, 0.75rem 0.75rem,
    0.25rem 0.35rem, 1rem 1rem, 0.75rem 0.75rem, 1rem 1rem, 0.75rem 0.75rem,
    1.25rem 1.25rem, 0.5rem 0.5rem, 0.75rem 0.75rem, 0.5rem 0.5rem,
    0.6rem 0.6rem, 1.25rem 1.25rem, 0.75rem 0.75rem, 0.5rem 0.5rem,
    0.75rem 0.75rem, 1.25rem 1.25rem;
  background-position: 20% 10%, 5% 20%, 10% 75%, 22% 35%, 30% 30%, 40% 50%,
    45% 20%, 65% 20%, 85% 30%, 98% 90%, 20% 80%, 50% 80%, 75% 80%, 89% 75%,
    90% 95%, 60% 70%, 65% 40%, 90% 20%;
  background-repeat: no-repeat;
  animation: twinkle ease-in 0.3s infinite;
}

.container-logo {
  justify-items: normal;
  align-items: center;
  font-size: 2rem;
  max-height: fit-content;
}

.title-logo {
  background-color: transparent;
  color: whitesmoke;
}
span {
  color: yellow;
  font-weight: 900;
}

img {
  width: 90vw;
}

.container-timer {
  display: grid;
  border: yellow 0.15rem dotted;
  border-radius: 3rem;
  margin: 0 0.5rem 1rem;
}
.title-timer {
  font-size: 2rem;
}

.data-timer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

/* Container Form */
.Form {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}

.form-elements {
  display: grid;
  grid-template-rows: auto;
  gap: 0.5rem;
}
.titleForm {
  font-size: 1.75rem;
  color: antiquewhite;
}

input[type="text"],
input[type="email"],
input[type="number"],
input[type="behave"],
input[type="comments"],
textarea {
  border: yellow thin dotted;
  border-radius: 0.75rem;
  padding: 0.75rem;
  margin: 0.25rem 0 1rem;
  width: 80%;
  font-size: 1rem;
  line-height: 1;
  background-color: whitesmoke;
}

textarea {
  font-family: "Work Sans", sans-serif;
  resize: none;
  color: #000028;
  font-size: 2rem;
}

.question {
  color: white;
  font-size: 1.5rem;
  font-weight: 400;
}
.error {
  color: yellow;
  font-size: 1.5rem;
}

.button {
  border: none;
  height: 4rem;
  width: 80%;
  background-color: yellow;
  font-size: 1rem;
  margin-bottom: 2rem;
  border-radius: 10rem;
  color: blue;
  cursor: pointer;
}
.button:hover {
  background-color: white;
  color: rgba(223, 65, 58, 255);
  font-size: 1.5rem;
  height: 3.5rem;
  width: 90%;
}

/* Footer */

.footer {
  background-color: #000028;
  min-height: 20vh;
  background-image: radial-gradient(
      circle,
      rgba(255, 255, 255, 0.7) 20%,
      transparent 30%
    ),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
    radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%);
  background-size: 0.5rem 0.5rem, 0.25rem 0.25rem, 0.75rem 0.75rem,
    0.25rem 0.35rem, 1rem 1rem, 0.75rem 0.75rem, 1rem 1rem, 0.75rem 0.75rem,
    1.25rem 1.25rem, 0.5rem 0.5rem, 0.75rem 0.75rem, 0.5rem 0.5rem,
    0.6rem 0.6rem, 1.25rem 1.25rem, 0.75rem 0.75rem, 0.5rem 0.5rem,
    0.75rem 0.75rem, 1.25rem 1.25rem;
  background-position: 20% 10%, 5% 20%, 10% 75%, 22% 35%, 30% 30%, 40% 50%,
    45% 20%, 65% 20%, 85% 30%, 98% 90%, 20% 80%, 50% 80%, 75% 80%, 89% 75%,
    90% 95%, 60% 70%, 65% 40%, 90% 20%;
  background-repeat: no-repeat;
  animation: twinkle ease-in 0.3s infinite;
}

@keyframes twinkle {
  to {
    background-image: radial-gradient(
        circle,
        rgba(255, 255, 255, 0.7) 20%,
        transparent 30%
      ),
      radial-gradient(circle, #fff 20%, transparent 30%),
      radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
      radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
      radial-gradient(circle, #fff 20%, transparent 30%),
      radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
      radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
      radial-gradient(circle, #fff 20%, transparent 30%),
      radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
      radial-gradient(circle, #fff 20%, transparent 30%),
      radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
      radial-gradient(circle, #fff 20%, transparent 30%),
      radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
      radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
      radial-gradient(circle, #fff 20%, transparent 30%),
      radial-gradient(circle, rgba(255, 255, 255, 0.7) 20%, transparent 30%),
      radial-gradient(circle, #fff 20%, transparent 30%),
      radial-gradient(circle, #fff 20%, transparent 30%);
  }
}

a:link {
  text-decoration: white;
  color: aliceblue;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

a:active {
  text-decoration: none;
}

/* media */

@media only screen and (min-width: 40rem) {
  .container-logo {
    font-size: 3rem;
    margin-bottom: -10rem;
  }
  .overlayclass {
    font-size: 1rem;
  }
  .msg2 {
    font-size: 0.75rem;
  }

  img {
    width: 80vw;
  }

  .container-timer {
    margin: 0 0.75rem 1rem;
  }

  .title-timer {
    font-size: 3rem;
  }

  .data-timer {
    font-size: 1.75rem;
    margin-bottom: 1.25rem;
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  textarea {
    font-size: 1.5rem;
    margin: 0.25rem 0 2rem;
  }

  .titleForm {
    font-size: 2.5rem;
    margin-top: 8rem;
  }
  .question {
    font-size: 2rem;
  }

  .button {
    height: 4rem;
    font-size: 2rem;
  }
  .button:hover {
    font-size: 3rem;
    height: 4.5rem;
  }
}

@media only screen and (min-width: 60rem) {
  img {
    width: 40vw;
  }
  .Container-Header {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: repeat(2, 1fr);
    gap: 2rem;
  }
  .imgHeader {
    grid-column: 2/3;
    grid-row: 1/4;
  }

  .form-elements {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
    align-items: center;
  }

  .form-elements :nth-child(6) {
    grid-row-start: 3;
    grid-row-end: 4;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .form-elements :nth-child(9) {
    grid-row-start: 5;
    grid-row-end: 6;
    grid-column-start: 1;
    grid-column-end: 3;
  }

  input[type="text"],
  input[type="email"],
  input[type="number"],
  textarea {
    margin: 0.25rem 0 2.75rem;
  }

  .container-timer {
    grid-column: 1/2;
    grid-row: 1/2;
    border-radius: 3rem;
  }

  .button {
    height: 5rem;
    font-size: 3rem;
  }

  .btn {
    grid-column: 1/3;
  }

  .button:hover {
    font-size: 4rem;
    height: 5.5rem;
  }
  .footer {
    height: 70vw;
  }

  .ImgFoot img {
    width: 98vw;
  }

  .ImgFormMed img {
    max-width: 110%;
  }
}
@media only screen and (min-width: 80rem) {
  .FormContainer {
    width: 100%;
  }

  .overlayclass {
    font-size: 1.5rem;
  }
  .msg2 {
    font-size: 1rem;
  }
}
